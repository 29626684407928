const selectInit = () => {
  const lists = document.querySelectorAll(".selectbox");
  return lists.forEach(
    (item) =>
      new window.vanillaSelectBox(`#${item.id}`, {
        maxHeight: 400,
        search: false,
        placeHolder: "Choose options",
        translations: { all: "All", items: "Items" },
      })
  );
};

export default selectInit;
