import utils from "../../utils";
import { echartSetOption } from "./echarts-utils";

/* -------------------------------------------------------------------------- */
/*                             Echarts Doughnut Chart                         */
/* -------------------------------------------------------------------------- */

const echartsDoughnutChartInit = () => {
  const $doughnutChartEl = document.querySelectorAll(
    ".echart-doughnut-chart-example"
  );

  if ($doughnutChartEl) {
    $doughnutChartEl.forEach((item) => {
      // Get options from data attribute
      const userOptions = utils.getData(item, "options");
      const data = utils.getData(item, "echart-data");
      const chart = window.echarts.init(item);

      const getDefaultOptions = () => ({
        // legend: false,
        legend: {
          left: "center",
          textStyle: {
            color: utils.getGrays()["600"],
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            center: ["50%", "55%"],
            top: "40px",
            avoidLabelOverlap: false,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data,
          },
        ],
        tooltip: {
          trigger: "item",
          padding: [7, 10],
          backgroundColor: utils.getGrays()["100"],
          borderColor: utils.getGrays()["300"],
          textStyle: { color: utils.getColors().dark },
          borderWidth: 1,
          transitionDuration: 0,
          axisPointer: {
            type: "none",
          },
        },
      });

      echartSetOption(chart, userOptions, getDefaultOptions);
    });
  }

  const tabs = document.querySelectorAll("[data-bs-toggle='tab']");
  tabs.forEach((tab) => {
    tab.addEventListener("shown.bs.tab", () => {
      const key = tab.href.split("#").pop();
      const echartItems = document
        .getElementById(key)
        .querySelectorAll(".echart-doughnut-chart-example");

      if (echartItems) {
        echartItems.forEach((item) => {
          // Get options from data attribute
          const chart = window.echarts.init(item);

          chart.resize();
        });
      }
    });
  });
};

export default echartsDoughnutChartInit;
