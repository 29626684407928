const toggleCollapseInit = () => {
  const toggleId = document.getElementById("toggle-button");
  const toggleId2 = document.getElementById("toggle-button2");
  const toggleId3 = document.getElementById("toggle-button3");
  const toggleId4 = document.getElementById("toggle-button4");
  const standardCard = document.querySelector(".wrapperCard");
  const principleCard = document.querySelector(".principle__wrapperCard");
  const clientStandardCard = document.querySelector(".client-standard-card");
  const clientPrncipleCard = document.querySelector(".client-principle-card");

  function StandardCard() {
    const arrowIcon = document.getElementById("icon");
    const y = document.querySelectorAll(".standard__hide__element");

    if (standardCard.style.width === "185px") {
      standardCard.style.width = "500px";
      standardCard.style.minWidth = "500px";
      arrowIcon.classList.toggle("fa-chevron-left");

      for (let i = 0; i < y.length; i += 1) {
        y[i].style.display = "table-cell";
      }
    } else {
      standardCard.style.width = "185px";
      standardCard.style.minWidth = "185px";
      arrowIcon.classList.toggle("fa-chevron-right");

      for (let i = 0; i < y.length; i += 1) {
        y[i].style.display = "none";
      }
    }
  }
  toggleId?.addEventListener("click", StandardCard);

  function PrincipleCard() {
    const arrowIcon2 = document.getElementById("icon2");
    const hideElement = document.querySelectorAll(".principle__hide__element");

    if (principleCard.style.width === "230px") {
      principleCard.style.width = "500px";
      principleCard.style.minWidth = "500px";
      arrowIcon2.classList.toggle("fa-chevron-left");

      for (let i = 0; i < hideElement.length; i += 1) {
        hideElement[i].style.display = "table-cell";
      }
    } else {
      principleCard.style.width = "230px";
      principleCard.style.minWidth = "230px";
      arrowIcon2.classList.toggle("fa-chevron-right");

      for (let i = 0; i < hideElement.length; i += 1) {
        hideElement[i].style.display = "none";
      }
    }
  }
  toggleId2?.addEventListener("click", PrincipleCard);

  function ClientSpecific() {
    const arrowIcon3 = document.getElementById("icon3");

    if (clientStandardCard.style.width === "200px") {
      clientStandardCard.style.width = "328px";
      clientStandardCard.style.minWidth = "328px";
      arrowIcon3.classList.toggle("fa-chevron-left");
    } else {
      clientStandardCard.style.width = "200px";
      clientStandardCard.style.minWidth = "200px";
      arrowIcon3.classList.toggle("fa-chevron-right");
    }
  }
  toggleId3?.addEventListener("click", ClientSpecific);

  function PrincipleSpecific() {
    const arrowIcon4 = document.getElementById("icon4");

    if (clientPrncipleCard.style.width === "230px") {
      clientPrncipleCard.style.width = "328px";
      clientPrncipleCard.style.minWidth = "328px";
      arrowIcon4.classList.toggle("fa-chevron-left");
    } else {
      clientPrncipleCard.style.width = "230px";
      clientPrncipleCard.style.minWidth = "230px";
      arrowIcon4.classList.toggle("fa-chevron-right");
    }
  }
  toggleId4?.addEventListener("click", PrincipleSpecific);
};

export default toggleCollapseInit;
