const customjavascriptInit = () => {
  const selectId = document.getElementById("contactSelect");
  const showDeletedTableId = document.getElementById("showDeletedTable");
  const enableButton = document.getElementById("enableButton");

  function changeEvent(e) {
    if (e.target.value === "contact-only") {
      const contactOnly = document.querySelectorAll(".contact-only");
      contactOnly.forEach((item) => {
        const item1 = item;
        item1.disabled = true;
      });
    } else {
      const fullAccess = document.querySelectorAll(".contact-only");
      fullAccess.forEach((item) => {
        const item1 = item;
        item1.disabled = false;
      });
    }
  }
  selectId?.addEventListener("change", changeEvent);

  function showDeletedTable() {
    const deletedTable = document.getElementById("hideDeletedTable");
    const checkdeleted = deletedTable.classList.toggle("d-sm-inline-block");
    const showContent = document.getElementById("showButtonContent");

    if (checkdeleted) {
      showContent.innerHTML = "Hide Deleted";
    } else {
      showContent.innerHTML = "Show Deleted";
    }
  }
  showDeletedTableId?.addEventListener("click", showDeletedTable);

  function showEnableButton(e) {
    const disable = document.querySelectorAll(".disabledButton");
    if (e.target.value) {
      disable.forEach((item) => {
        const item1 = item;
        item1.disabled = false;
      });
    } else {
      disable.forEach((item) => {
        const item1 = item;
        item1.disabled = true;
      });
    }
  }
  enableButton?.addEventListener("change", showEnableButton);
};
export default customjavascriptInit;
